import { object } from 'prop-types';
import { graphql } from 'gatsby';
import { Article } from '../components/article';

function PostTemplate({ data }) {
  return (
    <Article data={data} />
  );
}

export const query = graphql`
  query wpPostTemplate($uri: String, $databaseIds: [Int]) {
    allWpPost(
      limit: 3
      sort: {order: DESC, fields: date}
      filter: {categories: {nodes: {elemMatch: {databaseId: {in: $databaseIds}}}}}
    ) {
      nodes {
        title
        uri
        date
        schemaMarkup {
          eaPostSchemaUploadDate
          eaPostSchemaVideoName
        }
      }
    }
    wpPage: wpPost(uri: { eq: $uri }) {
      ...SeoPost
      ...PostSchemaMarkup
      content
      title
      uri
      date
      schemaMarkup {
        eaPostSchemaUploadDate
        eaPostSchemaVideoName
      }
      author {
        node {
          name
          firstName
          lastName
        }
      }
      tags {
        nodes {
          name
        }
      }
      categories {
        nodes {
          databaseId
          name
          uri
          wpParent {
            node {
              name
            }
          }
        }
      }
      postSingleConfiguration {
        eaPostRelatedPosts {
          ... on WpPost {
            title
            uri
            date
            schemaMarkup {
              eaPostSchemaUploadDate
              eaPostSchemaVideoName
            }
          }
        }
        eaPostRelatedPracticeAreas {
          ... on WpPage {
            title
            uri
            date
            schemaMarkup {
              eaPostSchemaUploadDate
              eaPostSchemaVideoName
            }
            template {
              ... on WpTemplate_PracticeChild {
                practiceChildConfiguration {
                  eaPracticeShortName
                }
              }
            }
          }
        }
      }
    }
    attorneys: allContentfulPageAttorney {
      nodes {
        title
        wordpressDifferentNames
        uri
        attorney{
          authorImage {
            image {
              gatsbyImage(width:40, placeholder: NONE)
            }
            altText
          }
          firstName
          lastName
        }
      }
    }
  }
`;

PostTemplate.propTypes = {
  data: object,
};

PostTemplate.defaultProps = {
  data: {},
};

export default PostTemplate;
export { Head } from '../components/seo/seo';
